<script lang="ts" setup>
import { computed } from 'vue';

import type {
  BaseModalEmits,
  ConfirmDialog,
  Dialog,
} from '@core/dialogs';
import { DialogComponent } from '@core/dialogs';

import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import CmsContentModal from 'web/src/modules/dialogs/views/CmsContentModal/CmsContenModal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import CountDownModal from 'web/src/modules/dialogs/views/CountDownModal/CountDownModal.vue';

import type { UniversalModalViewProps } from './types';

const props = withDefaults(defineProps<UniversalModalViewProps>(), {});

const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const component = computed(() => {
  switch (props.modal.modalComponent) {
    case DialogComponent.CmsContentModal:
      return CmsContentModal;
    case DialogComponent.CountDownModal:
      return CountDownModal;
    case DialogComponent.CustomModal:
      return props.modal.customComponent;
    default:
      return ConfirmModal;
  }
});

const modalProps = computed<ConfirmDialog>(() => {
  let modalProps = { ...props.modal } as Partial<Dialog>;

  if (modalProps.modalComponent === DialogComponent.CustomModal) {
    modalProps = {
      ...modalProps,
      ...(modalProps.props ?? {}),
    };

    delete modalProps.props;
    delete modalProps.customComponent;
  }

  return {
    ...modalProps,
    isInnerModal: props.isInnerModal,
    width: !props.isInnerModal ? props.modal.width : undefined,
    isProfileLayout: props.isProfileLayout || props.modal.isProfileLayout,
  } as ConfirmDialog;
});
</script>

<template>
  <component v-auto-id="'UniversalModalView'"
    :is="component"
    :modal="modalProps"
    :is-alert="!!'1' && !isInnerModal"
    @close="onClose"
    @button-click="onButtonClick"
  />
</template>

import type {
  Ref,
} from 'vue';
import {
  computed,
  markRaw,
  toRef,
} from 'vue';

import type { DialogButtonClickAction, StoredDialog } from '@core/dialogs';
import {
  DialogAction,
  DialogComponent,
  useDialogsStore,
} from '@core/dialogs';

import type { VTooltipProps } from 'web/src/modules/dialogs/components/VTooltip/types';
import { ModalSelector } from 'web/src/modules/core/enums';
import {
  useDesktopModalStore,
} from 'web/src/modules/core/store';
import {
  useTooltipStore,
} from 'web/src/modules/dialogs/store';

export interface TheModalComposable {
  currentModalSelector: Ref<ModalSelector>;
  currentModal: Ref<StoredDialog | undefined>;
  hasDesktopModal: Ref<boolean>;
  isProfileLayout: Ref<boolean>;
  onCurrentModalClose(): void;
  onButtonClick(action: DialogButtonClickAction): void;
  hasTooltip: Ref<boolean>;
  tooltipProps: Ref<VTooltipProps>;
}

export default function useTheModal(): TheModalComposable {
  const dialogsStore = useDialogsStore();
  const currentDialog = toRef(dialogsStore, 'currentDialog');

  const desktopModalStore = useDesktopModalStore();
  const isProfileLayout = toRef(desktopModalStore, 'isProfileLayout');
  const hasDesktopModal = toRef(desktopModalStore, 'hasDesktopModal');

  const tooltipStore = useTooltipStore();
  const tooltip = toRef(tooltipStore, 'tooltip');

  const currentModal = computed<StoredDialog | undefined>(() => {
    if (!currentDialog.value?.dialog) {
      return undefined;
    }

    return {
      ...currentDialog.value.dialog,
      ...(currentDialog.value.dialog.modalComponent === DialogComponent.CustomModal
        ? {
            customComponent: markRaw(currentDialog.value.dialog.customComponent),
          }
        : {}),
    };
  });
  const currentModalSelector = computed(() => (
    hasDesktopModal.value ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY
  ));

  const hasTooltip = computed(() => !!tooltip.value);
  const tooltipProps = computed<VTooltipProps>(() => ({
    text: tooltip.value?.notificationText,
    isProfileModalTooltip: tooltip.value?.isProfileModalTooltip,
  }));

  function onButtonClick({ action, value }: DialogButtonClickAction): void {
    if (currentModal.value) {
      dialogsStore.callAction(currentModal.value.id, action, value);
    }
  }

  function onCurrentModalClose(): void {
    if (currentModal.value) {
      dialogsStore.callAction(currentModal.value.id, DialogAction.MODAL_CLOSE);
    }
  }

  return {
    currentModalSelector,
    currentModal,
    hasDesktopModal,
    isProfileLayout,
    onCurrentModalClose,
    onButtonClick,
    hasTooltip,
    tooltipProps,
  };
}

<script lang="ts" setup>
import { computed } from 'vue';

import type {
  BaseModalEmits,
  ConfirmDialog,
} from '@core/dialogs';
import { DialogComponent } from '@core/dialogs';

import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';

import type { CmsContentModalProps } from './types';

const props = withDefaults(defineProps<CmsContentModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const confirmModalProps = computed<ConfirmDialog>(() => {
  const {
    cmsKey,
    noPadding,
    ...modalProps
  } = props.modal;

  return {
    ...modalProps,
    modalComponent: DialogComponent.ConfirmModal,
  };
});
</script>

<template>
  <ConfirmModal v-auto-id="'CmsContenModal'"
    :modal="confirmModalProps"
    :is-alert="isAlert"
    is-scroll-bar-enabled
    class="cms-content-modal"
    :class="modal.class"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <VCmsContent
      :cms-key="modal.cmsKey"
      :no-padding="modal.noPadding"
      silent
    />
  </ConfirmModal>
</template>

<script lang="ts" setup>
import {
  provide,
} from 'vue';

import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';

import UniversalModalView from '../UniversalModalView/UniversalModalView.vue';
import { useTheModal } from './composables';

provide(ModalProvidable.modalContext, {
  insideModal: true,
});

const {
  currentModalSelector,
  currentModal,
  hasDesktopModal,
  isProfileLayout,
  onCurrentModalClose,
  onButtonClick,
  hasTooltip,
  tooltipProps,
} = useTheModal();
</script>

<template>
  <div v-auto-id="'TheModal'">
    <ModalPortal
      :selector="currentModalSelector"
    >
      <UniversalModalView
        v-if="currentModal"
        :modal="currentModal"
        :is-inner-modal="hasDesktopModal"
        :is-profile-layout="isProfileLayout"
        @close="onCurrentModalClose"
        @button-click="onButtonClick"
      />
    </ModalPortal>
    <Teleport
      v-if="hasTooltip"
      :to="currentModalSelector"
      :disabled="!hasTooltip"
    >
      <VTooltip
        v-bind="tooltipProps"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

import { Timer } from '@leon-hub/utils';

import type { BaseModalEmits } from '@core/dialogs';
import { DialogAction } from '@core/dialogs';

import { SButton } from '@components/buttons';

import { useBaseModalActions } from 'web/src/modules/dialogs/composables';

import type { CountDownModalProps } from './types';
import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';

withDefaults(defineProps<CountDownModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

let interval = 0;
const countDown = ref(60);

function startCountDown(): void {
  if (countDown.value) {
    countDown.value -= 1;
  }

  if (countDown.value === 0) {
    onButtonClick({
      action: DialogAction.MODAL_COUNTDOWN,
    });
  }
}

onMounted(() => {
  interval = Timer.setInterval(startCountDown, 1000);
});

onBeforeUnmount(() => {
  if (interval) {
    Timer.clearInterval(interval);
    interval = 0;
  }
});
</script>

<template>
  <ConfirmModal v-auto-id="'CountDownModal'"
    :modal="modal"
    :is-alert="isAlert"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <div :class="$style['countdown']">
      {{ $t('WEB2_SELF_EXCLUSION_AFTER') }}:
      <span :class="$style['countdown__timer']">{{ countDown }} {{ $t('JS_TIMER_TEXT_SECONDS') }}</span>
    </div>
    <template
      v-if="null"
      #footer
    >
      <SButton
        :label="$t('WEB2_MODAL_CANCEL')"
        kind="primary"
        size="large"
        full-width
        @click="onButtonClick({ action: DialogAction.MODAL_CLOSE })"
      />
    </template>
  </ConfirmModal>
</template>

<style lang="scss" module>
.countdown {
  @include regular\14\24\025;

  color: var(--TextDefault);

  &__timer {
    @include medium\14\24\025;

    color: $countdownTimeColor;
  }
}
</style>
